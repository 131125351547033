.coinsearch {
    width: 100%;
    display: flexbox;
    margin: 10px 0px;
    text-align: center;
}

.coinsearch-searchContainer {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 20px 10px;
    justify-content: space-between;
    margin: 0px 10px 0p 10px;
}

.coinsearch-searchContainer h1 {
    font-size: 18px;
}

.coinsearch-searchContainer input {
    padding: 10px 20px;
    border-radius: 20px;
    border: 1px solid rgba(150, 150, 150);
    box-shadow: 0px 0px 4px 0 rgba(150, 150, 150);
}

.coinsearch-table {
    display: flexbox;
    border: 1px solid rgba(150, 150, 150);
    border-radius: 20px;
    margin: 10px;
    text-align: center;
    box-shadow: 0 0 4px 0 rgba(150, 150, 150);
}

.coinsearch-table table {
    width: 100%;
    display: flexbox;
    align-items: center;
    justify-content: center;
}

.coinsearch-table th {
    padding: 10px 30px;
}

.coinsearch-table tr {
    text-align: center;
}
.tr-coin:hover {
    box-shadow: 0 0 4px 0 rgba(150, 150, 150);
    border-radius: 15px;
    cursor: pointer;
    transition: ease-in-out 100ms;
}

.coinsearch-table td {
    padding: 10px 0px;
}

.coinsearch-table__image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.coinsearch-table img {
    height: 35px;
    margin: 10px;
}

.stardiv {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.STAR {
    font-size: 18px;
}

.STARCLICK {
    font-size: 18px;
}

.STAR:hover {
    color: rgb(150, 150, 2);
}

a {
    color: black;
    text-decoration: none;
}

.tr-coin img {
    height: 35px;
    margin: 10px;
}

.tr-coin td {
    padding: 10px 10px;
    width: fit-content;
}

.tr-coin table {
    width: 100%;
    display: flexbox;
    align-items: center;
    justify-content: center;
}

.table-start > table {
    width: 100%;
}

@media screen and (max-width: 900px) {
    .mobile {
        display: none;
    }
}
