.account {
    display: flexbox;
}

.account-header {
    display: flex;

    justify-content: space-between;
    padding: 20px;
    align-items: center;
    margin: 10px;
    border: 1px solid rgba(150, 150, 150);
    box-shadow: 0px 0px 4px 0 rgba(150, 150, 150);
    border-radius: 15px;
}

.account-coin {
    border: 1px solid rgba(150, 150, 150);
    box-shadow: 0px 0px 4px 0 rgba(150, 150, 150);
    border-radius: 15px;
    padding: 20px;
    margin: 10px;
}

.account-header button {
    padding: 7px 15px;
    border: 1px solid rgba(150, 150, 150);
    box-shadow: 0px 0px 4px 0 rgba(150, 150, 150);
    border-radius: 15px;
    background-color: white;
    font-weight: bold;
}

.account-header button:hover {
    cursor: pointer;
    background-color: black;
    color: white;
    transition: ease-in-out 150ms;
}
