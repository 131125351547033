.navbar {
    display: flex;
    width: 100%;
    align-items: center;
    height: 75px;
    justify-content: center;
}

.navbar-container {
    display: flex;
    height: 85%;
    width: 100%;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255);
    box-shadow: 0px 0px 6px 0px rgba(150, 150, 150);
}

.navbar-logo {
    height: 100%;
    display: flex;
    flex: 0.1;
    padding: 0 10px;
    align-items: center;
    justify-content: center;
}

.navbar-logo a {
    font-size: 20px;
    color: black;
    text-decoration: none;
}

.navbar-sign {
    display: flex;
    flex: 0.9;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
}

.navbar-sign h4 {
    padding: 8px 15px;
    margin: 10px;
}

.navbar-sign a {
    color: black;
    text-decoration: none;
}

.navbar-sign a:hover {
    transition: ease 200ms;
    color: rgba(100, 100, 100);
}

.navbar-signup {
    border: 1px solid rgba(150, 150, 150, 0.5);
    border-radius: 2000px;
}

.navbar-signup:hover {
    color: white !important;
    background-color: black;
    transition: ease 200ms;
    box-shadow: 0px 0px 4px 0px rgba(150, 150, 150);
    cursor: pointer;
}
