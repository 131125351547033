.signin {
    display: flexbox;
    margin: 10px;
    padding-bottom: 22em;
}

.signin-container {
    padding: 80px 100px 30px;
    display: block;
    text-align: center;
}

.signin-button {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 100px;
}

.signin input {
    margin: 10px auto;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    width: 40%;
    min-width: 40%;
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255);
    box-shadow: 0px 0px 10px 0px rgba(150, 150, 150);
}

.signin-container p {
    margin-top: 20px;
}

.signin-button button {
    width: 40%;
    padding: 10px 20px;

    box-shadow: 0px 0px 10px 0px rgba(150, 150, 150);
    background-color: black;
    cursor: pointer;
    border-radius: 15px;
    color: white;
}

.account-check {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.account-check a {
    color: navy;
}

.account-check a:hover {
    text-decoration: underline;
}
